<template>
    <div class="container py-9">
        <div class="text-center">
            <loading-animation/><br/>
            Please wait while we check your session...
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import urls from '@/data/urls';

export default {
    name     : 'CheckStatusPage',
    computed : {
        ...mapGetters(['currentUser'])
    },
    beforeMount () {
        this.checkUserStatus();
    },
    methods : {
        ...mapActions(['signOut']),
        async checkUserStatus (user) {
            const response = await axios.get(urls.auth.status);
            if (response.data.loggedIn === false) {
                this.signOut();
                await axios.get(urls.auth.logout);
                this.$router.push('/login/').then(() => {
                    window.location.reload(true);
                });
            }
            this.$router.push('/').then(() => {
                window.location.reload(true);
            });
        }
    }
};
</script>

<style scoped>

</style>
